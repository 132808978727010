"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Strings_1 = require("../constants/Strings");
const WebpImage_1 = require("./common/WebpImage");
const InfoPane = () => {
    return (React.createElement("div", { id: "info" },
        React.createElement("h1", { className: "info-header" }, Strings_1.default.infoPane.name),
        React.createElement(WebpImage_1.default, { className: "info-picture bg-light", src: "../static/img/profile_square.jpeg.webp", fallback: "../static/img/profile_square.jpeg", alt: "Jesse's Portrait", height: 250, width: 250 }),
        React.createElement("p", { id: "traits" }, Strings_1.default.infoPane.traits),
        React.createElement("div", { className: "social-icons flex flex-row" }, Strings_1.default.infoPane.socialIcons.map((iconProps, i) => (React.createElement(SocialIcon, Object.assign({ key: i }, iconProps))))),
        React.createElement("footer", { id: "info-footer" },
            React.createElement("p", null,
                React.createElement("a", { href: "https://goo.gl/maps/kMWnC4nhxyVv9uqc6", rel: "noopener", target: "_blank" },
                    React.createElement("i", { className: "fas fa-map-marker-alt" }),
                    ' ',
                    Strings_1.default.infoPane.location)),
            React.createElement("span", null, Strings_1.default.infoPane.copyright))));
};
const SocialIcon = ({ url, iconClass, alt }) => {
    return (React.createElement("div", { className: "social-icon" },
        React.createElement("a", { href: url, target: "_blank", rel: "noopener", "aria-label": alt },
            React.createElement("i", { className: iconClass }))));
};
exports.default = InfoPane;
