"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_accessible_accordion_1 = require("react-accessible-accordion");
const ResumeAccordionSection = ({ heading, iconClass, children, }) => {
    return (React.createElement(react_accessible_accordion_1.AccordionItem, null,
        React.createElement(react_accessible_accordion_1.AccordionItemHeading, null,
            React.createElement(react_accessible_accordion_1.AccordionItemButton, null,
                React.createElement("h3", { style: { display: 'inline-block', position: 'relative', top: 4 } },
                    React.createElement("i", { className: `fas ${iconClass}` }),
                    " ",
                    heading))),
        React.createElement(react_accessible_accordion_1.AccordionItemPanel, null, children)));
};
exports.default = ResumeAccordionSection;
