"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const WebpImage_1 = require("../common/WebpImage");
const getSplitCourses = (courses) => {
    const halfWay = Math.ceil(courses.length / 2);
    return [courses.slice(0, halfWay), courses.slice(halfWay)];
};
const EducationItem = (props) => {
    const [leftCourses, rightCourses] = getSplitCourses(props.courses);
    return (React.createElement("div", { className: "education-item" },
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-sm-4 edu-info" },
                React.createElement("h4", { className: "edu-timeframe" }, props.timeFrame),
                React.createElement("h4", { className: "edu-school" }, props.school),
                React.createElement("p", { className: "edu-gpa" },
                    "GPA: ",
                    props.gpa),
                React.createElement("p", { className: "edu-location" },
                    React.createElement("i", { className: "fas fa-map-marker-alt" }),
                    " ",
                    props.location),
                props.image && (React.createElement(WebpImage_1.default, { className: "school-logo", src: props.image.webp, fallback: props.image.fallback, alt: `${props.school} logo`, width: 150, height: 150 }))),
            React.createElement("div", { className: "col-sm-8" },
                React.createElement("h4", { className: "edu-title" }, props.major),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-sm-6" },
                        React.createElement(CourseList, { courses: leftCourses })),
                    React.createElement("div", { className: "col-sm-6" },
                        React.createElement(CourseList, { courses: rightCourses })))))));
};
const CourseList = ({ courses }) => {
    return (React.createElement("ul", { className: "course-list" }, courses.map((course) => (React.createElement("li", { key: course }, course)))));
};
exports.default = EducationItem;
