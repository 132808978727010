"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CarouselTabs;
(function (CarouselTabs) {
    CarouselTabs["HOME"] = "HOME";
    CarouselTabs["RESUME"] = "RESUME";
    CarouselTabs["PORTFOLIO"] = "PORTFOLIO";
    CarouselTabs["CONTACT"] = "CONTACT";
})(CarouselTabs || (CarouselTabs = {}));
exports.default = CarouselTabs;
