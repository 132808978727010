"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const recharts_1 = require("recharts");
const SkillsSection = ({ codeMap }) => {
    const orderedLangs = [...codeMap.entries()]
        .sort((a, b) => (a[1] > b[1] ? -1 : 1))
        .splice(0, 8)
        .map(([k, v]) => ({ language: k, Kilobytes: v / 1000 }));
    return (React.createElement(React.Fragment, null,
        React.createElement("h4", null, "Code on Github"),
        React.createElement(recharts_1.ResponsiveContainer, { height: 300, width: "100%" },
            React.createElement(recharts_1.BarChart, { width: 600, height: 300, data: orderedLangs, margin: { top: 20, right: 30, left: 20, bottom: 20 } },
                React.createElement(recharts_1.CartesianGrid, { strokeDasharray: "3 3" }),
                React.createElement(recharts_1.XAxis, { dataKey: "language", label: { value: 'Language', position: 'bottom' } }),
                React.createElement(recharts_1.YAxis, { domain: [1, 'dataMax'], label: {
                        value: 'Amount (Kilobytes)',
                        angle: -90,
                        position: 'insideBottomLeft',
                    }, scale: "log" }),
                React.createElement(recharts_1.Tooltip, null),
                React.createElement(recharts_1.Bar, { dataKey: "Kilobytes", fill: "#00d4d4" })))));
};
exports.default = SkillsSection;
