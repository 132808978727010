"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const BaseAPI_1 = require("./BaseAPI");
class GithubAPI extends BaseAPI_1.default {
    static fetchRepos() {
        return super.get('https://api.github.com/users/jrbartola/repos');
    }
    static fetchRepoLanguages({ repoName }) {
        return super.get(`https://api.github.com/repos/jrbartola/${repoName}/languages`);
    }
}
exports.default = GithubAPI;
