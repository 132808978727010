"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const WebpImage_1 = require("../common/WebpImage");
const AwardItem = (props) => {
    return (React.createElement("div", { className: "award-item" },
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-sm-4 award-info" },
                React.createElement("h4", { className: "award-timeframe" }, props.timeFrame),
                React.createElement("h4", { className: "award-title" }, props.event),
                React.createElement("p", { className: "award-location" },
                    React.createElement("i", { className: "fas fa-map-marker-alt" }),
                    " ",
                    props.location),
                props.image && (React.createElement(WebpImage_1.default, { className: "event-logo", src: props.image.webp, fallback: props.image.fallback, alt: `${props.event} logo`, width: 150, height: 150 }))),
            React.createElement("div", { className: "col-sm-8" },
                props.awardUrl && (React.createElement("a", { href: props.awardUrl, target: "_blank", rel: "noopener" },
                    React.createElement("h4", { className: "award-title" },
                        props.award,
                        " ",
                        React.createElement("i", { className: "fas fa-link" })))),
                !props.awardUrl && React.createElement("h4", { className: "award-title" }, props.award),
                React.createElement("p", null, props.description)))));
};
exports.default = AwardItem;
