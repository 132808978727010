"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const PortfolioRow = ({ leftCell, rightCell }) => {
    return (React.createElement("div", { className: "row" },
        React.createElement("div", { className: "col-md-6 portfolio-left" },
            React.createElement("div", { className: "portfolio-cell-outer", style: {
                    backgroundImage: `url("${leftCell.image.webp}"), url("${leftCell.image.fallback}")`,
                } },
                React.createElement("div", { className: "portfolio-cell" },
                    React.createElement("a", { href: leftCell.projectUrl, target: "_blank", rel: "noopener" },
                        React.createElement("h3", { className: "portfolio-title" }, leftCell.title),
                        React.createElement("p", null, leftCell.tooltip))))),
        React.createElement("div", { className: "col-md-6 portfolio-right" }, rightCell !== undefined && (React.createElement("div", { className: "portfolio-cell-outer", style: {
                backgroundImage: `url("${rightCell.image.webp}"), url("${rightCell.image.fallback}")`,
            } },
            React.createElement("div", { className: "portfolio-cell" },
                React.createElement("a", { href: rightCell.projectUrl, target: "_blank", rel: "noopener" },
                    React.createElement("h3", { className: "portfolio-title" }, rightCell.title),
                    React.createElement("p", null, rightCell.tooltip))))))));
};
exports.default = PortfolioRow;
