"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const CarouselTabs_1 = require("../constants/CarouselTabs");
const NavItemTabProps = {
    [CarouselTabs_1.default.HOME]: {
        iconClass: 'fa-home',
    },
    [CarouselTabs_1.default.RESUME]: {
        iconClass: 'fa-address-card',
    },
    [CarouselTabs_1.default.PORTFOLIO]: {
        iconClass: 'fa-book-open',
    },
    [CarouselTabs_1.default.CONTACT]: {
        iconClass: 'fa-envelope',
    },
};
const Navbar = ({ selectedTab, onTabClick }) => {
    const coverOffsetClass = `tab-${selectedTab}`;
    return (React.createElement("nav", { className: "navbar navbar-expand-md navbar-light bg-light" },
        React.createElement("div", { className: `nav-item-cover ${coverOffsetClass}` }),
        React.createElement("div", { className: "collapse navbar-collapse" },
            React.createElement("div", { className: "divider" }),
            Object.keys(NavItemTabProps).map((tabName, i) => (React.createElement(React.Fragment, { key: tabName },
                React.createElement(NavItem, Object.assign({ tabIndex: 0, selected: selectedTab === tabName, onClick: () => onTabClick(tabName), caption: tabName }, NavItemTabProps[tabName])),
                React.createElement("div", { className: "divider" })))))));
};
const NavItem = ({ selected, onClick, iconClass, caption, tabIndex, }) => {
    const selectedClass = selected ? 'selected' : '';
    return (React.createElement("div", { className: `nav-item flex-column ${selectedClass}`, role: "button", "aria-label": caption, onClick: onClick, tabIndex: tabIndex },
        React.createElement("i", { className: `fas ${iconClass} nav-icon` }),
        React.createElement("span", { className: "nav-link" }, caption)));
};
exports.default = Navbar;
