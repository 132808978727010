"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Navbar_1 = require("./Navbar");
const InfoPane_1 = require("./InfoPane");
const Carousel_1 = require("./Carousel");
const CarouselTabs_1 = require("../constants/CarouselTabs");
const Container = () => {
    const [selectedTab, setSelectedTab] = React.useState(CarouselTabs_1.default.HOME);
    return (React.createElement("div", { id: "container", className: "container-fluid" },
        React.createElement(Navbar_1.default, { selectedTab: selectedTab, onTabClick: setSelectedTab }),
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-md-4" },
                React.createElement(InfoPane_1.default, null)),
            React.createElement("div", { className: "col-md-8 content-carousel" },
                React.createElement(Carousel_1.default, { selectedTab: selectedTab })))));
};
exports.default = Container;
