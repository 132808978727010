"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const WebpImage = (_a) => {
    var { src, fallback } = _a, extra = __rest(_a, ["src", "fallback"]);
    return (React.createElement("picture", null,
        React.createElement("source", { srcSet: src, type: "image/webp" }),
        React.createElement("img", Object.assign({ src: fallback }, extra))));
};
exports.default = WebpImage;
