"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Strings_1 = require("../../constants/Strings");
const PortfolioRow_1 = require("./PortfolioRow");
const makePortfolioCellPairs = (portfolioCells) => {
    let cellPairs = [];
    // Group each of the cells into pairs of two
    for (let i = 0; i < portfolioCells.length / 2; i++) {
        cellPairs.push(portfolioCells.slice(2 * i, 2 * (i + 1)));
    }
    return cellPairs;
};
const PORTFOLIO_CELL_PAIRS = makePortfolioCellPairs(Strings_1.default.tabs.PORTFOLIO.cells);
const PortfolioTab = () => {
    return (React.createElement("div", { id: "portfolio" },
        React.createElement("div", { className: "carousel-header" },
            React.createElement("h2", null, Strings_1.default.tabs.PORTFOLIO.heading)),
        PORTFOLIO_CELL_PAIRS.map(([leftCell, rightCell], i) => (React.createElement(PortfolioRow_1.default, { key: i, leftCell: leftCell, rightCell: rightCell })))));
};
PortfolioTab.displayName = 'Portfolio';
exports.default = PortfolioTab;
