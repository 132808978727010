"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const component_1 = require("@loadable/component");
const windowUtils_1 = require("../util/windowUtils");
const CarouselTabs_1 = require("../constants/CarouselTabs");
const HomeTab = component_1.default(() => Promise.resolve().then(() => require(/* webpackChunkName: "HomeTab" */ './tabs/HomeTab')));
const ResumeTab = component_1.default(() => Promise.resolve().then(() => require(/* webpackChunkName: "ResumeTab" */ './tabs/ResumeTab')));
const PortfolioTab = component_1.default(() => Promise.resolve().then(() => require(/* webpackChunkName: "PortfolioTab" */ './tabs/PortfolioTab')));
const ContactTab = component_1.default(() => Promise.resolve().then(() => require(/* webpackChunkName: "ContactTab" */ './tabs/ContactTab')));
const TabContentComponents = {
    [CarouselTabs_1.default.HOME]: HomeTab,
    [CarouselTabs_1.default.RESUME]: ResumeTab,
    [CarouselTabs_1.default.PORTFOLIO]: PortfolioTab,
    [CarouselTabs_1.default.CONTACT]: ContactTab,
};
/**
 * Defines the carousel container displaying the selected tab's data
 */
const Carousel = ({ selectedTab }) => {
    const [windowWidth, setWindowWidth] = React.useState(windowUtils_1.getWindowWidth());
    const updateWindowWidth = React.useCallback(() => {
        setWindowWidth(windowUtils_1.getWindowWidth());
    }, []);
    React.useEffect(() => {
        window.addEventListener('resize', updateWindowWidth);
    }, []);
    const TabContent = TabContentComponents[selectedTab];
    return (React.createElement("div", { className: "row carousel-container" },
        React.createElement("div", { id: "carousel" },
            windowWidth >= 692 && React.createElement(TabContent, null),
            windowWidth < 692 && (React.createElement("div", null, Object.entries(TabContentComponents).map(([tabName, ContentComponent]) => (React.createElement(ContentComponent, { key: tabName }))))))));
};
exports.default = Carousel;
