"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const WebpImage_1 = require("../common/WebpImage");
const ExperienceItem = (props) => {
    return (React.createElement("div", { className: "experience-item" },
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-sm-4 job-info" },
                React.createElement("h4", { className: "job-timeframe" }, props.timeFrame),
                React.createElement("h4", { className: "job-company" }, props.company),
                React.createElement("p", { className: "job-location" },
                    React.createElement("i", { className: "fas fa-map-marker-alt" }),
                    " ",
                    props.location),
                props.image && (React.createElement(WebpImage_1.default, { className: "company-logo", src: props.image.webp, fallback: props.image.fallback, alt: `${props.company} logo`, width: 150, height: 150 }))),
            React.createElement("div", { className: "col-sm-8" },
                React.createElement("h4", { className: "job-title" }, props.jobTitle),
                React.createElement("p", null, props.description)))));
};
exports.default = ExperienceItem;
