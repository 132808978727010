"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_accessible_accordion_1 = require("react-accessible-accordion");
require("react-accessible-accordion/dist/fancy-example.css");
const Strings_1 = require("../../constants/Strings");
const GithubAPI_1 = require("../../util/GithubAPI");
const windowUtils_1 = require("../../util/windowUtils");
const AwardItem_1 = require("./AwardItem");
const EducationItem_1 = require("./EducationItem");
const ExperienceItem_1 = require("./ExperienceItem");
const ResumeAccordionSection_1 = require("./ResumeAccordionSection");
const SkillsSection_1 = require("./SkillsSection");
const ResumeTab = () => {
    const [codeMap, setCodeMap] = React.useState(new Map([
        ['Javascript', 1192159],
        ['CSS', 570597],
        ['HTML', 124604],
        ['Python', 118751],
        ['Swift', 89462],
        ['Typescript', 72043],
        ['Java', 56195],
        ['Scala', 17646],
    ]));
    const handleGitHubResponse = React.useCallback((responses) => {
        const newCodeMap = responses.reduce((acc, resp) => {
            return Object.keys(resp).reduce((acc2, language) => acc2.set(language, (acc2.get(language) || 0) + resp[language]), acc);
        }, new Map());
        setCodeMap(newCodeMap);
    }, []);
    React.useEffect(() => {
        GithubAPI_1.default.fetchRepos()
            .then((resp) => {
            const repos = resp.filter((obj) => !obj.fork).map((obj) => obj.name);
            Promise.all(repos.map((repoName) => GithubAPI_1.default.fetchRepoLanguages({ repoName }))).then(handleGitHubResponse);
        })
            .catch((resp) => {
            console.error('Something went wrong...', resp);
        });
    }, []);
    const width = windowUtils_1.getWindowWidth();
    const updatedText = width >= 692 ? Strings_1.default.tabs.RESUME.lastUpdated : '';
    return (React.createElement("div", { id: "resume" },
        React.createElement("div", { className: "carousel-header flex" },
            React.createElement("h2", null, Strings_1.default.tabs.RESUME.heading),
            React.createElement("h3", null,
                updatedText,
                "\u00A0\u00A0",
                React.createElement("a", { className: "download-btn", href: "/resume", target: "_blank", "aria-label": "Jesse's resume" },
                    React.createElement("i", { className: "fas fa-download" })))),
        React.createElement(react_accessible_accordion_1.Accordion, { allowMultipleExpanded: true, allowZeroExpanded: true },
            React.createElement(ResumeAccordionSection_1.default, { heading: Strings_1.default.tabs.RESUME.workExperience.heading, iconClass: "fa-briefcase" }, Strings_1.default.tabs.RESUME.workExperience.items.map((experienceProps, i) => (React.createElement(ExperienceItem_1.default, Object.assign({ key: i }, experienceProps))))),
            React.createElement(ResumeAccordionSection_1.default, { heading: Strings_1.default.tabs.RESUME.education.heading, iconClass: "fa-graduation-cap" }, Strings_1.default.tabs.RESUME.education.items.map((educationItemProps, i) => (React.createElement(EducationItem_1.default, Object.assign({ key: i }, educationItemProps))))),
            React.createElement(ResumeAccordionSection_1.default, { heading: Strings_1.default.tabs.RESUME.awards.heading, iconClass: "fa-medal" }, Strings_1.default.tabs.RESUME.awards.items.map((awardItemProps, i) => (React.createElement(AwardItem_1.default, Object.assign({ key: i }, awardItemProps))))),
            React.createElement(ResumeAccordionSection_1.default, { heading: Strings_1.default.tabs.RESUME.skills.heading, iconClass: "fa-chart-bar" },
                React.createElement(SkillsSection_1.default, { codeMap: codeMap })))));
};
ResumeTab.displayName = 'Resume';
exports.default = ResumeTab;
