"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const DEFAULT_OPTIONS = {
    headers: {},
    query: {},
};
// Wrapper around the Fetch API that produces JSON automatically
const safeFetch = (input, ...args) => {
    return fetch(input, ...args).then((resp) => __awaiter(void 0, void 0, void 0, function* () {
        let ret;
        if (!resp.ok) {
            let throwable;
            try {
                ret = yield resp.json();
            }
            catch (err) {
                throwable = Object.assign(Object.assign({}, err), { status: resp.status });
                throw throwable;
            }
            throwable = { status: resp.status, response: ret };
            throw throwable;
        }
        return resp.json().catch(() => ({}));
    }));
};
class BaseAPI {
    static mergeWithQueryParams(url, { query = {} } = {}) {
        // Remove undefined values so they don't get misinterpreted by the backend
        const queryNames = Object.keys(query).filter((k) => query[k] !== undefined);
        if (query && queryNames.length > 0) {
            return queryNames
                .slice(1)
                .reduce((acc, k) => `${acc}&${k}=${query[k]}`, `${url}?${queryNames[0]}=${query[queryNames[0]]}`);
        }
        return url;
    }
    static getDefaultHeaders({ requestType }) {
        if (requestType === 'POST') {
            return { 'Content-Type': 'application/json' };
        }
        return {};
    }
    static get(url, options = DEFAULT_OPTIONS) {
        const { headers: __headers } = options, optionsNoHeaders = __rest(options, ["headers"]);
        return safeFetch(`${BaseAPI.mergeWithQueryParams(url, optionsNoHeaders)}`, Object.assign({ method: 'GET', headers: Object.assign(Object.assign({}, BaseAPI.getDefaultHeaders({ requestType: 'GET' })), options.headers) }, optionsNoHeaders));
    }
    static post(url, options = DEFAULT_OPTIONS) {
        const { headers: __headers } = options, optionsNoHeaders = __rest(options, ["headers"]);
        return safeFetch(`${BaseAPI.mergeWithQueryParams(url, optionsNoHeaders)}`, Object.assign({ method: 'POST', headers: Object.assign(Object.assign({}, BaseAPI.getDefaultHeaders({ requestType: 'POST' })), options.headers) }, optionsNoHeaders));
    }
}
exports.default = BaseAPI;
