"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Strings_1 = require("../../constants/Strings");
const HomeTab = () => {
    return (React.createElement("div", { id: "home" },
        React.createElement("div", { className: "carousel-header" },
            React.createElement("h2", null, Strings_1.default.tabs.HOME.heading)),
        Strings_1.default.tabs.HOME.bio.map((paragraph, i) => (React.createElement("p", { key: i, className: "bio" }, paragraph)))));
};
HomeTab.displayName = 'Home';
exports.default = HomeTab;
