"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const Strings_1 = require("../../constants/Strings");
const ContactTab = () => {
    return (React.createElement("div", { id: "contact" },
        React.createElement("div", { className: "carousel-header" },
            React.createElement("h2", null, Strings_1.default.tabs.CONTACT.heading)),
        React.createElement("div", { className: "contact-info" },
            React.createElement("p", null, "Feel free to get in touch! I'm always interested in meeting new people. If you have any ideas, projects, or questions don't hesitate to shoot me an email!"),
            React.createElement("p", { className: "email-label" }, "Email"),
            React.createElement("p", null,
                React.createElement("a", { className: "contact-email", href: "mailto:jrbartola@gmail.com" }, "jrbartola@gmail.com")))));
};
exports.default = ContactTab;
